@import '~app/mixins';

.text {
  display: flex;
  flex-direction: column;
  color: $color-grey;

  label {
    font-weight: bold;
    margin-bottom: 4px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    pointer-events: none;
  }

  .input {
    border: 1px solid #cacaca;
    color: $color-grey;
    border-radius: 4px;
    background-color: $color-white;
    padding: 12px 8px;
    outline: 0;
    font-size: 13px;
    width: 100%;

    &::-ms-expand {
      display: none;
    }
  }
}
